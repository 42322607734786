<!-- 借贷中心 -->
<template>
  <div class="loan-center">
    <div class="data-block theme-bg">
      <div class="my-level">
        <div class="level-item f28">
          <div class="word f color-666">{{ $i18n.t("lo_credit_rating") }}</div>
          <div class="value font-b font-bold color-word">{{ $i18n.t("lo_ordinary") }}</div>
        </div>
        <div class="level-item f28">
          <div class="word color-666">{{ $i18n.t("lo_credit_score") }}</div>
          <div class="value font-b font-bold color-333">{{ userInfo.score }}</div>
        </div>
      </div>
    </div>
    <div class="loan-content">
      <div class="cus-loan">
        <div class="title f32 color-666">{{ $i18n.t("lo_method") }}:</div>
        <router-link to="/loan-record" class="record color-999 f28" v-if="loanConfig.length > 0">{{ $i18n.t("lo_record") }}</router-link>
      </div>
      <div class="loan-list">
        <div class="loan-item f28" v-for="(item, index) in loanConfig" :key="index">
          <!-- <div class="select active"></div> -->
          <div
            :class="orderStatus === index ? 'select active' : 'select'"
            @click="selectOrder(index)"
          ></div>
          <div class="loan-name color-000">{{ $i18n.t("lo_scheme") }}#{{ index + 1 }}</div>
          <div class="loan-item-info">
            <div class="data-item">
              <div class="word color-666">{{ $i18n.t("lo_loan_amount") }}</div>
              <div class="price">
                <img src="../assets/images/usdc.svg" alt="" />
                <div class="num font-b font-bold color-333">{{ item.loanAmount }}</div>
              </div>
            </div>
            <div class="data-item">
              <div class="word color-666">{{ $i18n.t("lo_daily_interest") }}</div>
              <div class="price">
                <img src="../assets/images/usdc.svg" alt="" />
                <div class="num font-b font-bold color-333">{{ item.loanRate }}%</div>
              </div>
            </div>
            <div class="data-item">
              <div class="word color-666">{{ $i18n.t("lo_loan_date") }}</div>
              <div class="price">
                <img src="../assets/images/usdc.svg" alt="" />
                <div class="num font-b font-bold color-333">
                  {{ item.loanDay }} {{ $i18n.t("lo_day") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn loan-btn w-100 font-m f32" @click="comfirmLoan" v-if="loanConfig.length > 0">
        {{ $i18n.t("lo_confirm") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orderItem: {},
      orderStatus: null,
      orderCount: {},
      userInfo: {
        user: {
          address: "",
        },
      },
      loanConfig: [],
    };
  },
  async created() {
    await this.getUserInfo();
    this.getLoanConfigList();
  },
  methods: {
    selectOrder(index) {
      this.orderStatus = index;
      this.orderItem = this.loanConfig[index];
    },
    async getUserInfo() {
      let that = this;
      let res = await this.$http.getUserInfo();
      console.log(res);
      if (res.code === 0) {
        that.userInfo = res.data;
      }
    },
    getLoanConfigList() {
      this.$http
        .getLoanConfig({
          page: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.loanConfig = res.data.list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    comfirmLoan() {
      let that = this;
      if (this.orderStatus == null) {
        this.$toast(this.$i18n.t("lo_tips"));
        return;
      }
      this.$http
        .addLoanApply({
          configId: that.orderItem.ID,
        })
        .then((res) => {
          if (res.code == 0) {
            this.$router.push("/loan-record");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.cus-loan {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
<style lang="less">
.loan-center {
  background: #fff;
  .data-block {
    padding: 0.48rem 0.48rem;
    padding-top: 1.36rem;
    padding-bottom: 0.78rem;
    .my-level {
      background: rgba(255, 255, 255, 0.5);
      padding: 0.3rem 0.48rem;
      border-radius: 0.32rem;
      display: flex;
      .level-item {
        width: 50%;
        text-align: left;
        line-height: 0.4rem;
        .word {
          margin-bottom: 0.12rem;
        }
      }
    }
  }
  .loan-content {
    border-top-left-radius: 0.32rem;
    border-top-right-radius: 0.32rem;
    background: #fff;
    transform: translateY(-0.3rem);
    position: relative;
    padding: 0 0.48rem;
    padding-bottom: 0.48rem;
    .title {
      text-align: left;
      padding: 0.48rem 0;
    }
    .loan-btn {
      margin-top: 0.7rem;
    }
  }
}
</style>
